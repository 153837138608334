<template>
  <section>
    <breadcrumb :title="$t('nomenclators')" :subtitle="$t('provinces')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts"
          ref="table"
          :paginated="true"
          :per-page="itemsPerPage"
          :current-page="currentPage"
      >

        <b-table-column field="name" :label="$t('ID')" v-slot="props">
            <span class="col id">
              #{{ props.row.id }}
            </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('name')" v-slot="props">
            <span class="col name">
              {{ props.row.name }}
            </span>
        </b-table-column>

        <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
       <span class="col common bold">
         {{ props.row.subdivision_Code }}
       </span>
        </b-table-column>

        <b-table-column field="acronym" :label="$t('acronym')" sortable v-slot="props">
          <b-tag type="is-tag-success success" rounded v-if="props.row.acronym">
            <span class="text-status pending"> {{ props.row.acronym }}</span>
          </b-tag>
        </b-table-column>


        <b-table-column field="created" :label="$t('created')" sortable v-slot="props">
        <span class="col common">
           {{ formatYYYYMMDD(props.row.createdAt) }}
        </span>
        </b-table-column>

        <b-table-column field="updates" :label="$t('updates')" sortable v-slot="props">
        <span class="col common">
           {{ formatYYYYMMDD(props.row.updatedAt) }}
        </span>
        </b-table-column>


        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div @click="editProvince(props.row)">
              <b-icon type="is-green-accent" icon="pencil" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>

        </b-table-column>
      </b-table>
      <paginate v-if="false" :count="totalRecords" :per_page="itemsPerPage" :current-page="currentPage"/>
    </section>
    <b-modal
        v-model="isModalChangeProvinceActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modals-edit-province :province="province" @reload="getProvinces"
                              @close="props.close"></modals-edit-province>
      </template>
    </b-modal>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions} from "vuex";
import ModalsEditProvince from "@/components/modals/modalsEditProvince";

export default {
  name: "ProvinceComponent",
  components: {ModalsEditProvince, Paginate, Breadcrumb},
  data() {
    return {
      propsModals: {},
      desserts: [],
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      isModalChangeProvinceActive: false,
      province: null
    }
  },
  methods: {
    ...mapActions({
      fetchProvinces: 'FETCH_CUBAN_STATES'
    }),
    reload() {
      window.location.reload()
    },
    getProvinces() {
      return new Promise(resolve => {
        this.fetchProvinces()
            .then(value => {
              this.desserts = value
              resolve(value)
            })
      })
    },
    editProvince(props) {
      this.province = props
      this.isModalChangeProvinceActive = !this.isModalChangeProvinceActive
    },
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
  },
  created() {
    this.getProvinces()
  }

}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;

  &.id {
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    color: #C1C1C1;
  }

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.common {
    font-size: 16px;
    color: #0B2B46;
  }

  &.bold {
    font-weight: bold !important;
  }
}

.text-status {
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.pending {
    color: #289BA4;
  }

}

.tag:not(body).is-rounded {
  border-radius: 290486px;
  min-width: 80px;
}
</style>