<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('edit_province') }}</div>
          <button @click="closed" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <div class="columns is-flex is-justify-content-space-between is-align-items-center">
            <div class="column is-half">
              <b-field :label="`${$t('code')}*`">
                <b-input v-model="code" type="text" custom-class="input-code"></b-input>
              </b-field>
            </div>
            <div class="column is-2">
              <button @click="save" class="button save is-primary mt-30">{{ $t('save') }}</button>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "modalsEditProvince",
  data() {
    return {
      code: null,
    }
  },
  props: ['province'],
  methods: {
    ...mapActions({
      editProvince: 'EDIT_PROVINCE'
    }),
    save() {
      let edit = {
        code: this.code,
        id: this.province.id
      }
      this.editProvince(edit)
          .then(() => {
            this.closed()
          })
    },
    closed() {
      this.$emit('close')
      this.$emit('reload')
      window.location.reload()
    }
  },
  created() {
    this.code = this.province.subdivision_Code
  }
}
</script>

<style scoped lang="scss">

</style>